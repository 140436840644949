<template>
	<div>
		<auth-template>
			<div class="sign-in">
				<ValidationObserver v-slot="{ invalid }">
					<form class='auth-template-form' @submit.prevent='onSubmit' >
						<h1 class='heading-3'>{{ $t("auth.sign_in.title") }} </h1>
						<p class='sign-in__desc'>{{ $t("auth.sign_in.title_description") }}</p>

						<ValidationProvider :name='$t("auth.sign_in.email")' rules="required|email" v-slot='{errors, classes}'>
							<v-text-field
								:label='$t("auth.sign_in.email")'
								:placeholder='$t("auth.sign_in.placeholder.email")'
								name='email'
								type='email'
								:class='classes'
								v-model='login'
								:errorMessage='errors[0]'
								required
							/>
						</ValidationProvider>

						<ValidationProvider :name='$t("auth.sign_in.password")' rules="required" v-slot='{errors, classes}'>
							<v-text-field
								:label='$t("auth.sign_in.password")'
								:placeholder='$t("auth.sign_in.placeholder.password")'
								name='password'
								:class="classes"
								:type="password_show ? 'text' : 'password'"
								v-model='password'
								:errorMessage='errors[0]'
								required
								:icon='password_show ? "eye-show": "eye-hide"'
								:iconAction="showPassword"
							/>
						</ValidationProvider>

						<div class="form-btns">
							<div class="row align-items-center">
								<div class="col-6">
									<router-link class='app-link green--text' :to="$i18nRoute({name: 'password-recovery'})">{{ $t('auth.sign_in.forgot_password') }}</router-link>
								</div>
								<div class="col-6 pl-0">
									<v-button block color='green white--text' :disabled="invalid" @click='onSubmit' :loading='loading'>{{ $t('auth.sign_in.come_in') }}</v-button>
								</div>
							</div>
						</div>

						<div class='divider' />

						<div class="sign-in-bot">
							<span class='sign-in-bot__desc'>{{ $t("auth.sign_in.not_registred_messages") }}</span>
							<v-button block outline xsmall color='green' :to="$i18nRoute({name: 'sign-up'})">{{$t('auth.sign_in.register')}}</v-button>
						</div>
					</form>
				</ValidationObserver>
			</div>
		</auth-template>
	</div>
</template>

<script>
	import AuthTemplate from '@/views/auth/components/auth-template';
	import { login, user, course } from '@/services/request';
	import { Trans } from "@/plugins/Translation";

	export default {
		data: () => ({
			login: '',
			password: '',
			password_show: false,
			loading: false
		}),
		components: {
			AuthTemplate,
		},
		methods: {
			onSubmit() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;

				login({login: this.login, password: this.password}).then(res => {
					this.$store.dispatch('auth/login', res.data);

					if(this.$route.params.lang !== res.data.user.language) {
						Trans.changeLanguage(res.data.user.language).then(() => {
							document.title = this.$t(`routes.${this.$route.name}`) + ' - ' + process.env.VUE_APP_TITLE;
						});
					}

					user().then(res => {
						this.$store.commit("auth/user", res.data);
						this.$store.commit('currency', res.data.currency);

						course().then(res => this.$store.commit("exchange_rates", res.data));
					});
					this.$router.push({ name: "home", params: {lang: Trans.defaultLanguage === res.data.user.language ? undefined : res.data.user.language}})
					this.$store.commit('notification/successMessage', {title: this.$t('account.booking.welcome') + ', ' + res.data.user.name + '!'})
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading = false)
			},
			showPassword() {
				this.password_show = !this.password_show;
			}
		}
	}
</script>

<style lang='scss'>
	@import "@/styles/global/mixins";
	@import "@/styles/global/variables";

	.sign-in {
		&__desc {
			margin: 15px 0 30px;
		}
		&-bot {
			&__desc {
				color: rgba($black, .3);
				margin-bottom: 22px;
				display: block;
			}
		}
		.divider {
			margin-top: 30px;
			margin-bottom: 25px;
		}
	}
</style>
